import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import { useSelector } from 'react-redux'
import ClearIcon from '@mui/icons-material/Clear'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: '550px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    '&::-webkit-scrollbar': {
        width: '3px',
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gold',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'gold',
    },
}

export default function ViewModal(props) {
    const { open, setOpen, viewData, selectedPayment } = props
    const handleClose = () => setOpen(false)

    return (
        <div style={{ zIndex: 1, display: 'flex', alignItems: 'center' }}>
            <Modal
                style={{ zIndex: 1 }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="clear_icon_div">
                            <ClearIcon className='clear_icon' onClick={handleClose} />
                        </div>
                        <h1 className='payment_information'>User Information</h1>
                        <div className='image_and_text_div_main checkout_image_and_text_div'>
                            <div className="image_and_text_div">
                                <div className='title_and_quantity_div'>
                                    <p className='shopping_text text_1 user_text'>Name</p>
                                    <p className='shopping_text user_text'>Email</p>
                                    <p className='shopping_text user_text'>Order comments</p>
                                    <p className='shopping_text user_text'>Remarks</p>
                                </div>
                            </div>
                            <div className="image_and_text_div">
                                <div className=''>
                                    <p className='shopping_text user_information_text user_text'>{viewData?.userInformation?.name}</p>
                                    <p className='shopping_text user_information_text user_text'>{viewData?.userInformation?.email}</p>
                                    <p className='shopping_text user_information_text user_text'>{viewData?.userInformation?.orderComment || '---'}</p>
                                    <p className='shopping_text user_information_text user_text'>{viewData?.userInformation?.remarks || '---'}</p>
                                </div>
                            </div>
                        </div>
                        <h1 className='payment_information'>Payment Information</h1>
                        <div className='image_and_text_div_main checkout_image_and_text_div' style={{ flexDirection: 'column' }}>
                            {selectedPayment?.remarks ? <p>
                                <b>Remarks</b>: {selectedPayment?.remarks}
                            </p> : null}
                            {selectedPayment?.orderComment ? <p>
                                <b>Comment</b>: {selectedPayment?.orderComment}
                            </p> : null}
                        </div>
                        <br />
                        {viewData?.items?.map((v, i) => {
                            return (
                                <div className='image_and_text_div_main checkout_image_and_text_div'>
                                    <div className="image_and_text_div">
                                        <div className='title_and_quantity_div'>
                                            <p className='shopping_text text_1'>{v?.title}</p>
                                            <p className='shopping_text'>System: {v?.style}</p>
                                        </div>
                                    </div>
                                    <div className="image_and_text_div">
                                        <div className='quantity_div'>
                                            <p className='shopping_text'>Qyt : {v?.quantity}</p>
                                            <p className='shopping_text'>{v?.price}$</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='image_and_text_div_main checkout_image_and_text_div total_amount_div'>
                            <div className="image_and_text_div">
                                <div className=''>
                                    <p>TotalAmount</p>
                                </div>
                            </div>
                            <div className="image_and_text_div">
                                <div className='quantity_div'>
                                    <p className='shopping_text'>{viewData?.totalAmount}$</p>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}