import apiUrl from "../Config/api"

const authApi = `${apiUrl}/api/admin`


const AUTH = {
    LOGIN: `${authApi}/logIn`,
    CHANGEPASSWORD:`${authApi}/change-password`,
}
const GET = {
    GETALLADMIN: `${authApi}/get-all-admins`,
    GETALLPAYMENT: `${authApi}/get-all-payments`,
    GETNEWPAYMENT: `${authApi}/get-new-payments`,
    GETAPPROVEDPAYMENT: `${authApi}/get-approved-payments`,
    GETREJECTPAYMENT: `${authApi}/get-reject-payments`,
    GET_DASHBOARD_ANALYTICS: `${authApi}/get-dashboard-analytics`,
    GET_CHART_DATA: `${authApi}/get-chart-data`,
}
const POST = {
    ADDADMIN: `${authApi}/add-admin`,
    DELETEADMIN: `${authApi}/delete-admin`,
    APPROVEPAYMENT: `${authApi}/approve-payment`,
    DELETEPAYMENT: `${authApi}/delete-payment`,
}

export {
    AUTH,
    GET,
    POST
}

