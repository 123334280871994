import React, { useState } from 'react';
import {
    AppstoreOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { AiOutlinePlusCircle, AiFillSetting } from 'react-icons/ai';
import { Button, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import MobileSideBar from './MobileSideBar';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import logo from '../../Assets/Images/logo.png'
import allPaths from '../../Config/paths'
const Sidebar = ({ collapsed, setCollapsed }) => {
    const history = useHistory();

    const location = window?.location?.pathname

    const getItem = (label, key, icon, to, children, type) => {
        const isActive = location === to
        const handleClick = () => {
            history.push(to)
        }
        return {
            key,
            icon,
            children,
            type,
            label: <span onClick={handleClick}>{label}</span>,
            onClick: handleClick,
            active: isActive,
        }
    }
    const items = [
        getItem('Dashboard', '1', <AppstoreOutlined />, '/home'),
        getItem('Payment', '4', <PaidOutlinedIcon />, '/payment'),
        getItem('Controller', '2', <AiOutlinePlusCircle />, '/admin/controller'),
        getItem('Setting', '3', <AiFillSetting />, '/setting'),
    ]
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <>
            <div className='side_bar1'>
                {collapsed ?
                    <div className='siderMain_div position'>
                        <div
                            style={{ width: 79 }} className='side_bar'>
                            <div onClick={()=>history.push(allPaths?.HOME)} className="logo_img_div">
                                <img src={logo} alt="" />
                            </div>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                theme="light"
                                inlineCollapsed={collapsed}
                                // items={items}
                                className='menu_items'
                            >
                                {items?.map((item) => (
                                    <Menu.Item key={item?.key} icon={item?.icon} onClick={item?.onClick} className={!item?.active ? null : 'active_tab'}>
                                        {item?.label}
                                    </Menu.Item>
                                ))}
                            </Menu>
                            <Button
                                onClick={toggleCollapsed}
                                className='toggle_btn'
                            >
                                {collapsed ? <><MenuUnfoldOutlined />
                                </> : <MenuFoldOutlined />}
                            </Button>
                        </div>
                    </div>
                    :
                    <div className='siderMain_div position'>
                        <div
                            className='side_bar bar_side'>
                            <div onClick={()=>history.push(allPaths?.HOME)} className="side_menu_logo_img_div">
                                <img src={logo} alt="" />
                            </div>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                theme="light"
                                inlineCollapsed={collapsed}
                                className='menu_items'
                            >
                                {items?.map((item) => (
                                    <Menu.Item key={item?.key} icon={item?.icon} onClick={item?.onClick} className={item?.active ? 'active_tab' : null}>
                                        {item?.label}
                                    </Menu.Item>
                                ))}
                            </Menu>
                            <Button
                                type="primary"
                                onClick={toggleCollapsed}
                                className='buttn'
                            >
                                {collapsed ? <><MenuUnfoldOutlined />
                                </> : <MenuFoldOutlined />}
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <div className='side_bar2'>
                <MobileSideBar />
            </div>
        </>
    );
};

export default Sidebar