import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Home, Login, Setting, AdminController, Payment } from '../Screens'
import allPaths from './paths'
import { Result, Button } from 'antd'
import { MenuLayout } from '../Components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GET } from '../utils/apis'
import axios from 'axios'

const Page404 = (props) => {
    // const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
            // onClick={() => history.push('/')}
            >Back Home</Button>}
        />
    )
}

const Routes = () => {
    const user = useSelector(state => state?.authReducer?.user)
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(true)
    const [analytics, setAnaltytics] = useState({})
    const [chartData, setChartData] = useState()

    useEffect(() => {
        getDashboardAnalytics()
        getChartData()
    }, [])

    const getChartData = () => {
        axios.get(`${GET?.GET_CHART_DATA}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setChartData(data?.data)
                }
                else {
                    console.log('e', data)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getDashboardAnalytics = () => {
        axios.get(`${GET?.GET_DASHBOARD_ANALYTICS}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAnaltytics(data?.data)
                }
                else {
                    console.log('e', data)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    return (
        <Router>
            <Switch>
                <Route path={allPaths?.LOGIN} exact component={Login} />
                <MenuLayout path={allPaths.HOME} exact component={() => <Home analytics={analytics} chartData={chartData} collapsed={collapsed} setCollapsed={setCollapsed} />} collapsed={collapsed} setCollapsed={setCollapsed} />
                <MenuLayout path={allPaths.SETTING} exact component={() => <Setting collapsed={collapsed} setCollapsed={setCollapsed} />} collapsed={collapsed} setCollapsed={setCollapsed} />
                <MenuLayout path={allPaths.ADMINCONTROLLER} exact component={() => <AdminController collapsed={collapsed} setCollapsed={setCollapsed} />} collapsed={collapsed} setCollapsed={setCollapsed} />
                <MenuLayout path={allPaths.PAYMENT} exact component={() => <Payment collapsed={collapsed} setCollapsed={setCollapsed} />} collapsed={collapsed} setCollapsed={setCollapsed} />
                <Route path='/:page404' exact component={Page404} />
            </Switch>
        </Router >
    )
}

export {
    Routes,
    Page404
}