import { Drawer, Space } from 'antd'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import { AppstoreOutlined } from '@ant-design/icons'
import { AiOutlinePlusCircle, AiFillSetting } from 'react-icons/ai'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const MobileSideBar = () => {
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState('left')
    const history = useHistory()
    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const getItem = (label, key, icon, to, children, type) => {
        const isActive = window.location.pathname === to
        const handleClick = () => {
            history.push(to)
            setOpen(false)
        }

        return {
            key,
            icon,
            children,
            type,
            label: <span onClick={handleClick}>{label}</span>,
            onClick: handleClick,
            active: isActive,
        }
    }

    const items = [
        getItem('Dashboard', '1', <AppstoreOutlined />, '/home'),
        getItem('Controller', '2', <AiOutlinePlusCircle />, '/admin/controller'),
        getItem('Setting', '3', <AiFillSetting />, '/setting'),
        getItem('Payment', '4', <PaidOutlinedIcon />, '/payment'),
    ]

    return (
        <>
            <Space>
                <button className='mobile_menu_btn' onClick={showDrawer}>
                    <MenuIcon />
                </button>
            </Space>
            <Drawer
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
            >
                <Menu
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    theme="light"
                >
                    {items?.map((item) => (
                        <Menu.Item key={item?.key} icon={item?.icon} onClick={item?.onClick} className={item?.active ? 'active_tab' : null}>
                            {item?.label}
                        </Menu.Item>
                    ))}
                </Menu>
            </Drawer >
        </>
    )
}
export default MobileSideBar