import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { AddButtonInModal } from '../index';
import axios from 'axios';
import { POST } from '../../utils/apis'
import { useSelector } from 'react-redux'
import { successMessage, errorMessage } from "../../utils/helpers"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal(props) {
    const user = useSelector(state => state?.authReducer?.user)
    const { open, setOpen } = props
    const handleClose = () => setOpen(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [role, setRole] = useState('')
    const [addLoading, setAddLoading] = useState(false)
    const add = () => {
        let formData = {
            email: email,
            password: password,
            role: role
        }
        if (!email || !password || !confirmPassword || !role) {
            return errorMessage('Please Provide All Values')
        }
        if (password != confirmPassword) {
            return errorMessage('Wrong Password')
        } else {
            setAddLoading(true)
            axios.post(POST?.ADDADMIN, formData)
                .then((res) => {
                    const { data } = res
                    if (data?.success) {
                        setAddLoading(false)
                        successMessage(
                            "Added Successfully"
                        )
                    }
                    else {
                        errorMessage(data?.message)
                        return setTimeout(() => {
                            setAddLoading(false)
                        }, 350)
                    }
                    setOpen(false)
                })
                .catch((e) => {
                    console.log(e)
                    errorMessage(e?.message)
                })
        }
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setRole('')
    }
    return (
        <div style={{ zIndex: 1 }}>
            <Modal
                style={{ zIndex: 1 }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <h1 className='admin_heading'>Admin/Manager</h1>
                        <div>
                            <input className='inp modal_inp' type='email' placeholder='Email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div>
                            <input className='inp modal_inp' type='password' placeholder='Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                        <div>
                            <input className='inp modal_inp' type='password' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                        </div>
                        <div>
                            <select placeholder='Select Role' className='select' value={role} onChange={(e) => setRole(e.target.value)}>
                                <option>Role</option>
                                {
                                    user?.role == 'Super Admin' ?
                                        <option value="Super Admin">Super Admin</option>
                                        :
                                        null
                                }
                                <option value="Admin">Admin</option>
                                <option value="Manager">Manager</option>
                            </select>
                        </div>
                        <div>
                            <AddButtonInModal onClick={add} addLoading={addLoading} Name='Add' />
                            <button className='modal_btn cancel' onClick={handleClose}>Cancel</button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}