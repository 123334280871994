import React, { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { AdminTable, Button ,BasicModal} from '../../Components'
import allPaths from '../../Config/paths'

const AdminController = ({collapsed}) => {
    const [open, setOpen] = useState(false);
    const [sideMenu, setSideMenu] = useState(false)
    // const history = useHistory()
    const handleButtonClick = () => {
        setTimeout(() => {
            setOpen(true);
        }, 360)
    };
    return (
        <div className='main'>
            <div className={collapsed ? 'user_full_main' : 'user_half_main'}>
                <div className='add_controllers_btn'>
                    <Button onClick={handleButtonClick} Name='Add' />
                </div>
                <div className='users_table controller_table'>
                    <AdminTable />
                </div>
                <BasicModal open={open} setOpen={setOpen} />
            </div>
        </div>
    )
}

export default AdminController
