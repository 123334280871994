import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { GET, POST } from '../../utils/apis'
import { errorMessage } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'
import { TablePagination } from '@mui/material'
import no_data_found from '../../Assets/Images/noDataImage.png'
import moment from 'moment'
import { Select } from 'antd'
import { Tabs } from 'antd'
import ViewModal from '../Modal/ViewModal'
import { Box, Chip, FormControl, MenuItem, OutlinedInput } from '@mui/material'

const { TabPane } = Tabs

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const PaymentTable = () => {
    const user = useSelector((state) => state?.authReducer?.user)
    const [allPayment, setAllPayment] = useState([])
    const [newPayment, setNewPayment] = useState([])
    const [approvedPayment, setApprovedPayment] = useState([])
    const [rejectedPayment, setRejectedPayment] = useState([])
    const [selectedPayment, setSelectedPayment] = useState({})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(8)
    const [open, setOpen] = useState(false)
    const [viewData, setViewData] = useState({ items: null, totalAmount: null })
    const [activeTab, setActiveTab] = useState('1')

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const getData = () => {
        axios
            .get(GET?.GETALLPAYMENT)
            .then((res) => {
                const { data } = res
                setAllPayment([...data?.data])
            })
            .catch((e) => {
                console.log(e)
                errorMessage(e?.message)
            })
    }
    const getNewPayment = () => {
        axios
            .get(GET?.GETNEWPAYMENT)
            .then((res) => {
                const { data } = res
                setNewPayment([...data?.data])
            })
            .catch((e) => {
                console.log(e)
                errorMessage(e?.message)
            })
    }

    const getApprovedPayment = () => {
        axios
            .get(GET.GETAPPROVEDPAYMENT)
            .then((res) => {
                const { data } = res
                setApprovedPayment([...data.data])
            })
            .catch((e) => {
                console.log(e)
                errorMessage(e?.message)
            })
    }

    const getRejectedPayment = () => {
        axios
            .get(GET.GETREJECTPAYMENT)
            .then((res) => {
                const { data } = res
                setRejectedPayment([...data.data])
            })
            .catch((e) => {
                console.log(e)
                errorMessage(e?.message)
            })
    }
    useEffect(() => {
        getNewPayment()
        getApprovedPayment()
        getRejectedPayment()
    }, [])
    const deleteButton = (id) => {
        swal({
            title: 'Are you sure?',
            text: 'You want to reject it?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let formData = {
                    _id: id,
                }
                axios
                    .post(POST?.DELETEPAYMENT, formData)
                    .then((res) => {
                        const { data } = res
                        if (data.success) {
                            getRejectedPayment()
                            getNewPayment()
                            swal('Rejected!', {
                                icon: 'success',
                            })
                        } else {
                            errorMessage(data?.message)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => getNewPayment())
            }
        })
    }

    const approveButton = (id) => {
        let formData = {
            _id: id,
        }
        axios
            .post(POST?.APPROVEPAYMENT, formData)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    getApprovedPayment()
                    getNewPayment()
                    swal('Approved!', {
                        icon: 'success',
                    })
                } else {
                    errorMessage(data?.message)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const Viewmore = (items, totalAmount, v) => {
        setViewData({ items: items, totalAmount: totalAmount })
        setSelectedPayment({ ...v })
        setOpen(true)
    }

    const handleTabChange = (key) => {
        setActiveTab(key)
    }

    return (
        <div>
            <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
                <TabPane className='payment_tabs' tab="New Request" key="1">
                    {/* <FormControl sx={{ width: '30%' }}>
            <Select
              style={{ width: '100%', marginBottom: '10px' }}
              mode="multiple"
              placeholder="Select Your Filter"
              value={filterVal}
              onChange={handleChangeFilter}
            >
              {filterValues.map((val) => (
                <Option key={val.val} value={val.val}>
                  {val.name}
                </Option>
              ))}
            </Select>
          </FormControl> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="table_head">
                                <TableRow>
                                    <StyledTableCell align="center" style={{ width: '5%' }}>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '15%' }}>
                                        Name
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '20%' }}>
                                        Email
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '10%' }}>
                                        Amount
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Created
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Status
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>
                                        Manage
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newPayment?.length ? (
                                    newPayment?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v, i) => (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">
                                                {i < 9 ? `0${i + 1}` : i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.email}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.amount}$
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ width: '20%' }}>
                                                {moment(v?.created).format('DD-MM-YYYY hh:mm:ss A')}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '10px', width: '15%' }}>
                                                {v?.isApproved ? 'Approved!' : 'New request arrived!'}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ display: 'flex' }}>
                                                <button onClick={() => Viewmore(v?.items, v?.amount, v)} className="delete_btn view_btn">
                                                    View More
                                                </button>
                                                <button
                                                    onClick={() => approveButton(v?._id)}
                                                    disabled={v?.isApproved}
                                                    className={v?.isApproved ? 'approve_btn_disable delete_btn approve' : 'delete_btn approve'}
                                                >
                                                    Approve
                                                </button>
                                                <button onClick={() => deleteButton(v?._id)} className="delete_btn">
                                                    Reject
                                                </button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <div className="nodatafound_img_div">
                                        <img src={no_data_found} alt="" />
                                        <p>{'No Data Found!'}</p>
                                    </div>
                                )}
                                <ViewModal open={open} setOpen={setOpen} viewData={viewData} selectedPayment={selectedPayment} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={newPayment?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TabPane>
                <TabPane className='payment_tabs' tab="Approvd Request" key="2">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="table_head">
                                <TableRow>
                                    <StyledTableCell align="center" style={{ width: '5%' }}>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '15%' }}>
                                        Name
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '20%' }}>
                                        Email
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '10%' }}>
                                        Amount
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Created
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Status
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="start" style={{ textAlign: 'center' }}>
                                        Manage
                                    </StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {approvedPayment?.length ? (
                                    approvedPayment?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v, i) => (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">
                                                {i < 9 ? `0${i + 1}` : i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.email}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.amount}$
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ width: '20%' }}>
                                                {moment(v?.created).format('DD-MM-YYYY hh:mm:ss A')}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '10px', width: '15%' }}>
                                                {v?.isApproved ? 'Approved!' : 'New request arrived!'}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <div className="nodatafound_img_div">
                                        <img src={no_data_found} alt="" />
                                        <p>{'No Data Found!'}</p>
                                    </div>
                                )}
                                <ViewModal open={open} setOpen={setOpen} viewData={viewData} selectedPayment={selectedPayment} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={approvedPayment?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TabPane>
                <TabPane className='payment_tabs' tab="Reject Request" key="3">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className="table_head">
                                <TableRow>
                                    <StyledTableCell align="center" style={{ width: '5%' }}>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '15%' }}>
                                        Name
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ textAlign: 'center', width: '20%' }}>
                                        Email
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ width: '10%' }}>
                                        Amount
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Created
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ width: '20%', textAlign: 'center' }}>
                                        Status
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rejectedPayment?.length ? (
                                    rejectedPayment?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v, i) => (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell component="th" scope="row" align="center">
                                                {i < 9 ? `0${i + 1}` : i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.email}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '50px' }}>
                                                {v?.amount}$
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ width: '20%' }}>
                                                {moment(v?.created).format('DD-MM-YYYY hh:mm:ss A')}
                                            </StyledTableCell>
                                            <StyledTableCell align="start" style={{ paddingLeft: '10px', width: '15%' }}>
                                                {v?.isApproved ? 'Approved!' : (v?.isApproved === false ? 'Rejected' : 'New request arrived!')}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <div className="nodatafound_img_div">
                                        <img src={no_data_found} alt="" />
                                        <p>{'No Data Found!'}</p>
                                    </div>
                                )}
                                <ViewModal open={open} setOpen={setOpen} viewData={viewData} selectedPayment={selectedPayment} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={rejectedPayment?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TabPane>
            </Tabs>

        </div>
    )
}

export default PaymentTable