const HOME = '/home'
const LOGIN = '/'
const SETTING = '/setting'
const ADMINCONTROLLER = '/admin/controller'
const PAYMENT = '/payment'
export default {
    HOME,
    LOGIN,
    SETTING,
    ADMINCONTROLLER,
    PAYMENT
}