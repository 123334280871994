import React, { useState } from 'react'
// import { MenuLayout } from '../../Components'
import { Column, Button, PieChart, MonthChart } from '../../Components'
import allPaths from '../../Config/paths'
// import { useHistory } from 'react-router-dom'

const Home = ({ collapsed, analytics, chartData }) => {
    const { countTotalRequests, countApprovedRequests, countPendingRequests } = analytics
    return (
        <div className={collapsed ? 'main' : 'main half_main'}>
            <div className={collapsed ? 'card_full_main' : 'card_half_main'}>
                <div className={collapsed ? 'dashboard_cards' : 'card_div'}>
                    <div className='card_heading_div'>
                        <div>
                            <h2 className='card_heading'>Total Payment Requests </h2>
                            <span>{countTotalRequests || 0}</span>
                        </div>
                    </div>
                    <div className='card_heading_div'>
                        <div>
                            <h2 className='card_heading'>Approve Payment Requests</h2>
                            <span>{countApprovedRequests || 0}</span>
                        </div>
                    </div>
                    <div className='card_heading_div recently_joined'>
                        <div>
                            <h2 className='card_heading'>Pending Payment Requests</h2>
                            <span>{countPendingRequests || 0}</span>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className='dashboard_bottom_cards'> */}
                    <div className='month_chart_div'>
                        {/* <div className='dashboard_bottom_cards_first_card'> */}
                        <MonthChart chartData={chartData} />
                    </div>
                    {/* <div className=''> */}
                    {/* <div className='dashboard_bottom_cards_first_card'>
                        <Column collapsed={collapsed} />
                    </div> */}
                    {/* <div className='dashboard_bottom_cards_second_card'> */}
                    <div className='dashboard_bottom_cards_second_card month_chart_div'>
                        <PieChart collapsed={collapsed} analytics={analytics} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home