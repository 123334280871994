import React from 'react'
import { Route } from 'react-router-dom'
import allPaths from '../../Config/paths'
import Sidebar from '../Sidebar/Sidebar'
import { Button } from '../Button/Button'
import { useLocation,useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../Redux/actions/authActions'

const MenuLayout = ({ component: Component, collapsed, setCollapsed, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => <AddMenu component={Component} collapsed={collapsed} setCollapsed={setCollapsed} />}
        />
    )
}
const AddMenu = ({ component: Component, collapsed, setCollapsed }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const Logout = () => {
        dispatch(loginUser())
        setTimeout(() => {
            history.push(allPaths?.LOGIN)
        }, 360)
    }
    return (
        <div className="helper_main">
            <div className={collapsed ? 'side_menu1' : (location?.pathname === '/admin/controller' || location?.pathname === '/setting' ? 'side_menu1 controller_open_collapsed' : 'side_menu1 open_collapsed')}>
                <div className='side_menu_theme_updated'>
                    <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                </div>
                <div className={collapsed ? 'user_logout_div' : 'user_logout_div logout_main_div'}>
                    <Button onClick={Logout} Name='Logout' />
                </div>
            </div>
            <div className='side_menu2'>
                <Component />
            </div>
        </div>
    )
}

export { MenuLayout }
