import React, { useState } from 'react'
import axios from "axios"
import { AUTH } from "../../utils/apis";
import { successMessage, errorMessage } from "../../utils/helpers"
import allPaths from '../../Config/paths';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loginUser } from '../../Redux/actions/authActions';
import { AddButtonInModal } from '../../Components';

const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [addLoading, setAddLoading] = useState(false)
    const handleSubmit = async () => {
        let formData = {
            email: email,
            password: password
        }
        if (!email || !password) {
            return errorMessage('please Provide Email and Password')
        } else {
            setAddLoading(true)
            axios.post(AUTH?.LOGIN, formData)
                .then((res) => {
                    const { data } = res
                    if (data?.success) {
                        successMessage(
                            "Successfully Login"
                        )
                        dispatch(loginUser(data?.admin));
                        return setTimeout(() => {
                            setAddLoading(false)
                            history.push(allPaths?.HOME)
                        }, 350)
                    }
                    else {
                        errorMessage(data?.message)
                        return setTimeout(() => {
                            setAddLoading(false)
                        }, 350)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    errorMessage(e?.message)
                })
        }
    }
    return (
        <div className='login_form_container'>
            <div>
                <h1 className='login_heading'>Login</h1>
            </div>
            <form method='post'>
                <div>
                    <input className='inp' type='email' placeholder='Email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    <input className='inp' type='password' placeholder='Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                </div>
            </form>
            <div>
                <AddButtonInModal className='login_btn' addLoading={addLoading} onClick={handleSubmit} Name='Login' />
            </div>
        </div>
    )
}

export default Login