import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
const Button = ({ Name, onClick, loading }) => {
    return (
        <>
            <button onClick={onClick} type="button" className="logout_btn user_logout" >
                {loading && <LoadingOutlined/>} &nbsp;
                {Name}
            </button>
        </>
    )
}

const AddButtonInModal = ({ Name, onClick, addLoading }) => {
    return (
        <>
            <button onClick={onClick} type="button" className="modal_btn add_btn_in_modal" >
                {addLoading && <LoadingOutlined/>} &nbsp;
                {Name}
            </button>
        </>
    )
}

export {
    Button,
    AddButtonInModal
}