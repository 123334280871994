import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const MonthChart = ({ chartData, collapsed }) => {
    const [chartState, setChartState] = useState({
        series: [{
            name: "Requests",
            data: chartData
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Payment Request by Month',
            align: 'left',
            margin: 20,
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#333',
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        }
    })

    return (
        <div id="chart">
            <ReactApexChart
                options={chartState}
                series={chartState?.series}
                type="line"
                className='month_chart'
                height={290}
            />
        </div>
    )
}

export default MonthChart

