import React, { useState } from 'react'
import { Button } from '../../Components'
import allPaths from '../../Config/paths'
import { useHistory } from 'react-router-dom'
import axios from "axios"
import { AUTH } from "../../utils/apis";
import { useSelector } from 'react-redux'
import { successMessage, errorMessage } from "../../utils/helpers"

const Setting = ({ collapsed }) => {
    const history = useHistory()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state?.authReducer?.user)
    const UpdatePassword = () => {
        let formData = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmNewPassword,
            _id: user?._id
        }
        setLoading(true)
        axios.post(AUTH?.CHANGEPASSWORD, formData)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(
                        "Update Password"
                    )
                    return setTimeout(() => {
                        setLoading(false)
                    }, 350)
                }
                else {
                    errorMessage(data?.message)
                    return setTimeout(() => {
                        setLoading(false)
                    }, 350)
                }
            })
            .catch((e) => {
                console.log(e)
            })
        setConfirmNewPassword('')
        setOldPassword('')
        setNewPassword('')
    }
    return (
        <div className='main'>
            <div>
                {/* <MenuLayout sideMenu={sideMenu} setSideMenu={setSideMenu} /> */}
            </div>
            <div className={collapsed ? 'user_full_main' : 'user_half_main'}>

                <div className='profile_setting'>
                    <h3 className='setting_heading'>Profile Setting</h3>
                    <div className='input_div setting_inp_div'>
                        <div>
                            <input className='inp setting_inp' type='email' placeholder='Email' disabled value={user?.email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div>
                            <input className='inp setting_inp' type='text' placeholder='Role' disabled value={user?.role} onChange={(e) => { setRole(e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='profile_setting'>
                    <h3 className='setting_heading'>Change Password</h3>
                    <div className='input_div setting_inp_div'>
                        <div>
                            <input className='inp setting_inp' type='password' placeholder='Old Password' value={oldPassword} onChange={(e) => { setOldPassword(e.target.value) }} />
                            <input className='inp setting_inp' type='password' placeholder='Confirm New Password' value={confirmNewPassword} onChange={(e) => { setConfirmNewPassword(e.target.value) }} />
                        </div>
                        <div>
                            <input className='inp setting_inp' type='password' placeholder='New Password' value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
                        </div>
                    </div>
                    <div className='setting_btn_div'>
                        <Button Name='Update' loading={loading} onClick={UpdatePassword} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Setting 
