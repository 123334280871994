import { LOGIN_USER, REMOVE_USER, ADD_USER } from '../types'
const initialState = {
    users: []
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER: {
            return { ...state, user: action.user }
        }
        default: {
            return state
        }
    console.log(state, 'reduces')
    }
}

export default loginReducer

