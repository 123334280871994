import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ collapsed, analytics }) => {
    const [chartState, setChartState] = useState({
        series: [analytics?.countApprovedRequests || 0, analytics?.countPendingRequests || 0, analytics.countTotalRequests || 0],
        options: {
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                    },
                }
            },
            legend: "none",
            chart: {
                width: '100%',
                type: 'pie',
            },
            labels: ['Approved Request ', 'Pending Request', 'Total Request'],
        }
    });

    return (
        <div id="chart">
            {analytics?.countApprovedRequests || analytics?.countPendingRequests || analytics?.countTotalRequests ?
                <div id="chart">
                    <ReactApexChart
                        options={chartState.options}
                        series={chartState.series}
                        type="pie"
                        width={'100%'}
                        className={collapsed ? 'Pie' : 'pie'}
                        height={280}
                    />
                </div> :
                <div style={{
                    border: '1px solid grey',
                    height: '160px',
                    width: '160px',
                    borderRadius: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <p style={{ fontSize: '14px', margin: '0px' }}> NO REQUEST FOUND</p>
                </div>
            }
        </div>
    );
};

export default PieChart;
