import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios"
import { GET, POST } from "../../utils/apis";
import { successMessage, errorMessage } from "../../utils/helpers"
import { useSelector } from 'react-redux';
import swal from 'sweetalert'
import { TablePagination } from '@mui/material'
import no_data_found from '../../Assets/Images/noDataImage.png'
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



export default function AdminTable() {
    const user = useSelector(state => state?.authReducer?.user)
    const [allAdmin, setAllAdmin] = useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(8)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    const getData = () => {
        axios.get(GET?.GETALLADMIN)
            .then((res) => {
                const { data } = res
                setAllAdmin(data?.admin)
            })
            .catch((e) => {
                console.log(e)
                errorMessage(e?.message)
            })
    }
    useEffect(() => {
        getData()
    }, [])
    const deleteButton = (id, role) => {
        if (user?._id == id) {
            errorMessage('you can not delete your account!')
        }
        else if (user?.role == 'Super Admin') {
            swal({
                title: "Are you sure?",
                text: "You want to delete it?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let formData = {
                            _id: id
                        }
                        axios.post(POST?.DELETEADMIN, formData)
                            .then((res) => {
                                const { data } = res
                                if (data.success) {
                                    swal("Deleted!", {
                                        icon: "success",
                                    });
                                }
                                else {
                                    errorMessage(data?.message)
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                });
        } else if (role == 'Super Admin') {
            errorMessage("Sorry you can't delete Super Admin")
        } else {
            swal({
                title: "Are you sure?",
                text: "You want to delete it?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let formData = {
                            _id: id
                        }
                        axios.post(POST?.DELETEADMIN, formData)
                            .then((res) => {
                                const { data } = res
                                if (data.success) {
                                    swal("Deleted!", {
                                        icon: "success",
                                    });
                                }
                                else {
                                    errorMessage(data?.message)
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                });
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead className='table_head'>
                        <TableRow>
                            <StyledTableCell align="center">S.No</StyledTableCell>
                            <StyledTableCell align="center" style={{ textAlign: 'center' }}>Email</StyledTableCell>
                            <StyledTableCell align="center" style={{ textAlign: 'center' }}>Password</StyledTableCell>
                            <StyledTableCell align="center" style={{ textAlign: 'center' }}>Role</StyledTableCell>
                            <StyledTableCell align="start" style={{ textAlign: 'center' }}>Created</StyledTableCell>
                            <StyledTableCell align="start" style={{ textAlign: 'center' }}>Management</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allAdmin?.length > 0
                            ?
                            (allAdmin?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((v, i) => (
                                <StyledTableRow key={i}>
                                    <StyledTableCell component="th" scope="row" align="center">
                                        {i < 9 ? `0${i + 1}` : i + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>{v?.email}</StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>******</StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>{v?.role}</StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>
                                        {moment(v?.created).format('DD-MM-YYYY hh:mm:ss A')}
                                    </StyledTableCell>
                                    <StyledTableCell align="start" style={{ textAlign: 'center' }}>
                                        <button onClick={() => deleteButton(v?._id, v?.role)} className='delete_btn'>Delete</button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )))
                            :
                            <div className='nodatafound_img_div admin_table_img'>
                                <img src={no_data_found} alt="" />
                                <p>{'No Data Found!'}</p>
                            </div>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={false}
                component="div"
                count={allAdmin?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}